<template>
  <div class="pa-5">
    <base-material-card
      color="success"
      icon="mdi-clipboard-text"
      inline
      title="自定义菜单"
      class="px-5 py-3 mb-5"
    >
      <div class="title-tip">
        最多可创建3个一级菜单，每个一级菜单下最多可创建5个二级菜单。
        <br>创建自定义菜单后，由于微信客户端缓存，需要24小时微信客户端才会展现出来。建议测试时可以尝试取消关注公众账号后再次关注，则可以看到创建后的效果。
        <br>因微信的消息接口规则变动，图文消息类型中图文数目只能为一条，请知悉。<span
          style="font-weight: bold;"
          @click="detailClick"
        >
          点击查看详情。
        </span>
      </div>
    </base-material-card>
    <v-card>
      <v-card-title class="indigo white--text text-h5">
        <div class="row-between">
          <span>菜单管理</span>
          <div>
            <v-icon
              color="#fff"
              @click="add"
            >
              mdi-plus
            </v-icon>
            <v-icon color="#fff">
              mdi-format-list-bulleted
            </v-icon>
          </div>
        </div>
      </v-card-title>
      <v-row
        class="pa-4"
        justify="space-between"
      >
        <!-- :filter="fetchUsers" -->
        <v-col cols="5">
          <v-treeview
            :active.sync="active"
            :items="items"
            :open.sync="open"
            activatable
            color="warning"
            open-on-click
            transition
          >
            <template v-slot:prepend="{ item }">
              <v-icon v-if="!item.children">
                mdi-account
              </v-icon>
            </template>
            <!-- <template v-slot:append="{ item }">
              <v-icon v-if="!item.children">
                mdi-account
              </v-icon>
            </template> -->
            <template v-slot:append="{ item }">
              <div>
                <v-icon
                  v-if="item.type"
                  @click="menuAddClick(item)"
                >
                  mdi-plus
                </v-icon>
                <v-icon
                  v-if="item.type || item.child"
                  @click="menuEditClick(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  v-if="item.type || item.child"
                  @click="menuDeleteClick(item)"
                >
                  mdi-delete
                </v-icon>
                <v-icon
                  v-if="item.type || item.child"
                  @click="menuUpClick(item.id)"
                >
                  mdi-arrow-up
                </v-icon>
                <v-icon
                  v-if="item.type || item.child"
                  @click="menuDownClick(item.id)"
                >
                  mdi-arrow-down
                </v-icon>
              </div>
            </template>
          </v-treeview>
        </v-col>

        <v-divider vertical />

        <v-col class="d-flex text-center">
          <!-- <v-scroll-y-transition mode="out-in"> -->
          <div v-if="isMenu">
            <div
              v-if="isSend === -1"
              style="align-self: center;"
            >
              <div>请选择订阅者点击菜单后，公众号做出的相应动作</div>
              <v-col cols="12">
                <v-row
                  align="center"
                  justify="center"
                  class="grey lighten-5"
                  style="height: 300px;"
                >
                  <v-card
                    v-for="(item, index) in menuList"
                    :key="index"
                    class="ma-3 pa-6"
                    outlined
                    tile
                    @click="cardCilck(index)"
                  >
                    <div class="bg">
                      <v-icon large>
                        {{ item.icon }}
                      </v-icon>
                    </div>
                    <div style="margin-top:10px">
                      {{ item.name }}
                    </div>
                  </v-card>
                </v-row>
              </v-col>
              <!-- <div
                id="editor"
                style="width:350px;height:100%"
              /> -->
            </div>
            <div
              v-show="isSend === 0"
              style="align-self: center;"
            >
              <div>订阅者点击该子菜单会收到以下消息</div>
              <div
                id="editor"
                style="width:350px;height:100%"
              />
              <div class="text-center">
                <v-btn
                  class="ma-2"
                  color="#4381e6"
                  @click="pageSubClick"
                >
                  保存
                </v-btn>
                <v-btn
                  class="ma-2"
                  color="#fff"
                  style="color:#000"
                  @click="backClick"
                >
                  返回
                </v-btn>
              </div>
            </div>
            <div
              v-if="isSend === 1"
              style="align-self: center;"
            >
              <div>订阅者点击该子菜单会跳到以下链接</div>
              <v-text-field
                ref="form"
                v-model="isMenu.url"
                label="手动输入链接到的页面地址"
              />
              <div style="text-align：left">
                必须得是http或者https开头的链接
              </div>
              <div class="text-center">
                <v-btn
                  class="ma-2"
                  color="#4381e6"
                  @click="pageSubClick"
                >
                  保存
                </v-btn>
                <v-btn
                  class="ma-2"
                  color="#fff"
                  style="color:#000"
                  @click="backClick"
                >
                  返回
                </v-btn>
              </div>
            </div>
            <div
              v-if="isSend === 3"
              style="align-self: center;"
            >
              <v-text-field
                v-model="appId"
                label="小程序的appid"
                :rules="rules"
              />
              <div style="text-align：left">
                添加已关联的小程序appid
              </div>
              <v-text-field
                v-model="appPage"
                label="小程序的页面路径"
                :rules="rules"
              />
              <div style="text-align：left">
                请准确填写小程序路径, 默认路径为小程序首页,支持改写为小程序商品链接(后台-商品列表-小程序商品链接复制)
              </div>
              <div class="text-center">
                <v-btn
                  class="ma-2"
                  color="#4381e6"
                  @click="appSubClick"
                >
                  保存
                </v-btn>
                <v-btn
                  class="ma-2"
                  color="#fff"
                  style="color:#000"
                  @click="isSend = -1"
                >
                  返回
                </v-btn>
              </div>
            </div>
            <!-- </v-scroll-y-transition> -->
          </div>
        </v-col>
      </v-row>
      <div class="text-right">
        <v-btn
          class="ma-2"
          color="#4381e6"
          @click="releaseClick"
        >
          发布
        </v-btn>
        <v-btn
          class="ma-2"
          color="#fff"
          style="color:#000"
          @click="previewClick"
        >
          预览
        </v-btn>
      </div>
    </v-card>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ title }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="menu"
                    label="菜单名称名字不多于8个汉字或8个字母*"
                    required
                    :rules="titleRules"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="#333 darken-1"
              text
              @click="cancelClick"
            >
              取消
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="sureClick"
            >
              确定
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      color="blue-grey"
    >
      {{ message }}
    </v-snackbar>
    <v-dialog
      v-model="deleteDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          删除提示
        </v-card-title>

        <v-card-text>
          确定要删除 {{ deleteName }} 菜单？
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="green darken-1"
            text
            @click="deleteDialog = false "
          >
            取消
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="DeleteSubClick"
          >
            确定
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="storeDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          设置附近门店
        </v-card-title>

        <v-card-text>
          设置成功！
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="green darken-1"
            text
            @click="storeDialog = false "
          >
            取消
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="storeDialog = false"
          >
            确定
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay
      z-index="999999"
      :value="overlay"
      class="text-center"
    >
      <div class="iphone">
        <div class="iphone-header">
          <i class="diy-phone-receiver" />
          <div class="diy-phone-title">
            重点项目调度系统
          </div>
        </div>
        <div class="diy-phone-contain">
          <div class="materialPrePanel">
            <div class="dt">
              <!-- <p
                class="single-summary"
                style="padding:10px;"
              >
              内容
              </p> -->
            </div>
          </div>
          <div class="footer">
            <v-icon
              color="#333"
              class="pr-3"
            >
              mdi-keyboard-close
            </v-icon>
            <v-menu
              v-for="(item, index) in items"
              :key="index"
              top
              :offset-y="true"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  color="#fff"
                  style="color:#000"
                  max-width="50%"
                  v-on="on"
                >
                  {{ item.name }}
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-for="(item1, index1) in item.children"
                  :key="index1"
                >
                  <v-list-item-title>{{ item1.name }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <!-- <div class="row-around">
              <div
                v-for="(item,index) in items"
                :key="index"
                class="li"
              >
                {{ item.name }}
              </div>
            </div> -->
          </div>
        </div>
        <div class="diy-phone-footer" />
      </div>
      <v-btn
        icon
        @click="overlay = false"
      >
        <v-icon style="font-size:30px">
          mdi-close-circle
        </v-icon>
      </v-btn>
    </v-overlay>
  </div>
</template>

<script>
  import WangEditor from 'wangeditor'
  export default {
    data: () => ({
      overlay: false, // 预览遮罩
      active: [],
      avatar: null,
      open: [],
      users: [],
      dialog: false,
      menu: '', // 菜单
      fristId: 0, // 一级菜单id
      items: [],
      menuId: '', // 当前点击菜单的id
      message: '', // 弹出提示
      snackbar: false,
      isSubmenu: false,
      titleRules: [
        v => !!v || '提示: 不能为空',
        v => v.length <= 8 || '提示: 输入超过字数限制',
      ],
      menuList: [{
        name: '发送信息',
        icon: 'mdi-email',
      }, {
        name: '跳转网页',
        icon: 'mdi-web ',
      }, {
        name: '返回附近门店',
        icon: 'mdi-comment-text-outline',
      }, {
        name: '打开小程序',
        icon: 'mdi-link-variant ',
      }],
      isSend: -1,
      rules: [
        value => !!value || '输入不能为空',
      ],
      pageRules: [
        value => /(http|https):\/\/([\w.]+\/?)\S*/.test(value) || '必须得是http或者https开头的链接',
      ],
      pageList: ['Foo', 'Bar', 'Fizz', 'Buzz'],
      page: '', // 手动输入页面路径
      deleteDialog: false,
      deleteId: '', // 要删除的id
      deleteName: '', // 要删除的菜单名称
      isEdit: false, // 判断是否是编辑
      appId: '', // appid
      appPage: '', // app页面
      storeDialog: false, // 设置附近门店弹窗
      isMenu: {},
      title: '',
      editor: '',
      editorobj: null,
    }),

    watch: {
      active (n, o) {
        console.log(n, o)
        // this.isSend = -1
        for (var i in this.items) {
          if (this.items[i].id === this.active[0]) {
            if (this.items[i].url) {
              this.isSend = 1
              this.isMenu = this.items[i]
            } else {
              this.isSend = -1
              this.isMenu = this.items[i]
            }
            for (var c in this.items[i].children) {
              if (this.items[i].children[c].id === this.active[0]) {
                this.isSend = 1
                this.isMenu = this.items[i].children[c]
              } else {
                this.isSend = -1
                this.isMenu = this.items[i]
              }
            }
          }
        }
        if (this.active.length === 0) {
          this.isSend = -1
        }
      },
      isSend (n, o) {
        console.log(n)
        if (n === 0) {
          console.log('editor', document.getElementById('editor'))
          this.editorFun()
        }
      },
    },
    created () {
      this.getMenuApi()
    },
    mounted () {
      // this.editorFun()
      console.log(document.getElementById('editor'))
    },
    methods: {
      editorFun () {
        const editor = new WangEditor('#editor')
        console.log(editor)
        editor.config.menus = [
          'head',
          'link',
          'video',
          'image',
        ]
        editor.config.showFullScreen = false
        editor.config.height = 200
        // editor.config.height = 800
        editor.create()
        // editor.txt.html('<p>' + this.contentList.content.text + '</p>') // 重新设置编辑器内容
        // editor.config.onchange = (newHtml) => {
        //   console.log('999', newHtml)
        //   this.editorData = newHtml
        // }
        this.editor = editor
      },
      // 获取菜单接口
      async getMenuApi () {
        await this.$axios.get('/wechat/wxd7a89e180b282117/menu').then(res => {
          console.log('res', res)
          for (var i in res.data.data) {
            res.data.data[i].id = i + 1
            if (res.data.data[i].url) {
              res.data.data[i].isUrl = true
            }
            if (res.data.data[i].children.length !== 0) {
              for (var c in res.data.data[i].children) {
                res.data.data[i].children[c].id = i + 1
                res.data.data[i].children[c].child = 'txt'
              }
            }
          }
          this.items = res.data.data
        }).catch(err => {
          console.log('err', err)
        })
      },
      // 添加菜单 最多可添加三条
      add () {
        if (this.items.length === 3) {
          // 提示
          this.snackbar = true
          this.message = '可创建最多3个一级菜单'
        } else {
          this.dialog = true
        }
        this.menu = ''
        this.title = '添加菜单'
        this.menuId = ''
      },
      // 保存小程序
      appSubClick () {
        if (!this.appPage || !this.appId) {
          this.snackbar = true
          this.message = 'appId 和 页面路径 都不能为空'
        } else {
          console.log(this.appId, this.appPage)
          // this.snackbar = true
          // this.message = '保存成功'
          this.$swal({
            title: '保存成功',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500,
          })
        }
      },
      // 返回
      backClick () {
        this.isMenu.isUrl = false
        this.isSend = -1
        // this.selected = null
        // this.active = []
        // console.log('000', this.isMenu)
      },
      // 跳转连接
      pageSubClick () {
        this.$refs.form.validate()
        if (!this.$refs.form.validate()) {
          this.snackbar = true
          this.message = '必须输入http或者https开头的地址'
          return false
        } else {
          var name = this.findSupLink(this.active[0])
          name.url = this.isMenu.url
          this.$swal({
            title: '保存成功',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500,
          })
          this.isSend = -1
        }

        // 不能为空
      },
      findSupLink (id, value) {
        if (value === 'key') {
          for (var t in this.items) {
            if (this.items[t].id === id) {
              return t
            } else {
              for (var h in this.items[t].children) {
                if (this.items[t].children[h].id === id) {
                  return h
                }
              }
            }
          }
        } else {
          for (var i in this.items) {
            if (this.items[i].id === id) {
              return this.items[i]
            } else {
              for (var c in this.items[i].children) {
                if (this.items[i].children[c].id === id) {
                  return this.items[i].children[c]
                }
              }
            }
          }
        }
      },
      // 点击菜单
      cardCilck (index) {
        if (index === 2) {
          this.isSend = -1
          this.storeDialog = true
        } else {
          this.isSend = index
        }
      },
      // 添加子项菜单
      menuAddClick (item) {
        this.menu = ''
        this.dialog = true
        this.menuId = item.id
        this.title = '添加菜单'
      },
      // 编辑
      menuEditClick (item) {
        this.dialog = true
        this.menu = item.name
        this.menuId = item.id
        this.isEdit = true
        this.title = '编辑菜单'
      },
      // 删除
      menuDeleteClick (item) {
        this.deleteId = item.id
        this.deleteName = item.name
        this.deleteDialog = true
      },
      // 上移
      menuUpClick (id) {
        var index = this.findSupLink(id, 'key')
        if (index === 0) {
          this.snackbar = true
          this.message = '已经是最顶部啦'
          return false
        } else {
          this.swapItems(this.items, index, index - 1)
        }
      },
      // 下移
      menuDownClick (id) {
        var index = this.findSupLink(id, 'key')
        if (index === this.items.length - 1) {
          this.snackbar = true
          this.message = '已经是最底部啦'
          return false
        } else {
          this.swapItems(this.items, index, index + 1)
        }
      },
      // 上移下移函数
      swapItems (arr, index1, index2) {
        arr[index1] = arr.splice(index2, 1, arr[index1])[0]
        return arr
      },
      DeleteSubClick () {
        for (var i in this.items) {
          if (this.items[i].id === this.deleteId) {
            this.items.splice(i, 1)
          } else {
            for (var c in this.items[i].children) {
              if (this.items[i].children[c].id === this.deleteId) {
                this.items[i].children.splice(c, 1)
              }
            }
          }
        }
        this.deleteDialog = false
      },
      // 点击取消
      cancelClick () {
        this.dialog = false
        this.isEdit = false
      },
      // 点击确定
      sureClick () {
        console.log(this.menuId)
        if (this.isEdit) {
          var name = this.findSupLink(this.menuId)
          name.name = this.menu
        } else {
          this.fristId++
          if (this.menuId) {
            // 添加到相应的父级菜单子项
            this.items.forEach(item => {
              if (item.id === this.menuId) {
                var obj = {
                  id: this.fristId,
                  name: this.menu,
                  key: null, // click等点击类型必须 菜单KEY值，用于消息接口推送，不超过128字节
                  url: '', // view、miniprogram类型必须 网页 链接，用户点击菜单可打开链接，不超过1024字节。 type为miniprogram时，不支持小程序的老版本客户端将打开本url。
                  children: [],
                  child: 'txt',
                  type: 'view', // 菜单的响应动作类型，view表示网页类型，click表示点击类型，miniprogram表示小程序类型
                }
                item.children.push(obj)
              }
            })
            this.menuId = ''
          } else {
            var obj = {
              id: this.fristId,
              type: 'view',
              name: this.menu,
              key: null,
              url: '',
              children: [],
            }
            this.items.push(obj)
            console.log('000')
          // this.menuApi(this.items)
          }
        }

        this.dialog = false
        this.isEdit = false
        this.menu = ''
      },
      // 菜单接口
      async menuApi (page) {
        console.log(page)
        await this.$axios.put('/wechat/wxd7a89e180b282117/menu', page).then(res => {
          console.log('res', res)
          this.$swal({
            title: '发布成功',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500,
          })
        }).catch(err => {
          console.log('err', err)
          this.$swal({
            title: '发布失败',
            icon: 'error',
            text: '原因:' + err,
            confirmButtonText: '知道了',
          })
        })
      },
      async fetchUsers (item) {
        console.log(item)
        // Remove in 6 months and say
        // you've made optimizations! :)
        // await pause(1500)

        // return fetch('https://jsonplaceholder.typicode.com/users')
        //   .then(res => res.json())
        //   .then(json => (item.children.push(...json)))
        //   .catch(err => console.warn(err))
      },
      // 预览
      previewClick () {
        this.overlay = true
      },
      // 发布
      releaseClick () {
        if (!this.items.length) {
          this.snackbar = true
          this.message = '请添加菜单'
          return false
        } else {
          for (var i in this.items) {
            delete this.items[i].id
            if (this.items[i].children.length > 0) {
              for (var c in this.items[i].children) {
                delete this.items[i].children[c].id
                delete this.items[i].children[c].child
              }
            }
          }
          this.menuApi(this.items)
        }
      },
      // 点击查看详情
      detailClick () {
        var url = 'https://mp.weixin.qq.com/cgi-bin/announce?action=getannouncement&announce_id=115383153198yAvN&version=&lang=zh_CN&token='
        window.open(url, '_blank')
      },
    },
  }
</script>

<style lang="scss" scoped>
.title-tip {
  padding: 10px;
  background-color: #e6f7ff;
  color: #666;
  margin-top: 10px;
  line-height: 30px;
}
.row-between {
  width: 41%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.iphone {
  width: 345px;
  border: 1px solid #ccc;
  border-radius: 15px;
  background: #fff;
  .diy-phone-receiver {
    margin: 13px auto;
    width: 98px;
    height: 34px;
    display: block;
    background: url('../../assets/phone.png') no-repeat;
    background-position: 0 -74px;
  }
  .diy-phone-title {
    width: 320px;
    height: 64px;
    padding-top: 32px;
    color: #fff;
    font-size: 18px;
    overflow: hidden;
    border: 1px solid #22292c;
    border-bottom: 0;
    cursor: pointer;
    display: block;
    margin: 0 auto;
    background: url('../../assets/phone.png') no-repeat;
    background-position: 0 0;
    text-align: center;
  }
  .diy-phone-contain {
    margin: 0 auto;
    width: 320px;
    min-height: 400px;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .materialPrePanel {
      margin-left: auto;
      margin-right: auto;
      overflow: hidden;
      width: 300px;
      margin-top: 20px;
      .dt {
        padding: 0;
        background-color: #fff;
        .single-summary {
          padding: 10px 0;
          line-height: 18px;
          color: #666;
          border: 1px solid #e7e7eb;
          font-size: 14px;
        }
      }
    }
    .footer {
      color: #000;
      background-color: #fff;
      height: 48px;
      display: flex;
      align-items: center;
      padding: 0 10px;
      .row-around {
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .li {
          width: 100%;
          text-align: center;
          border-left: 1px solid rgb(189, 187, 187);
        }
      }
    }
  }
  .diy-phone-footer {
    margin: 10px auto;
    width: 62px;
    height: 61px;
    display: block;
    background: url('../../assets/phone.png') no-repeat;
    background-position: -253px -70px;
  }
}
</style>
